import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

const DATE_INPUT_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TimePickerMinutesComponent),
	multi: true
};

import "./sis-time-picker.component.css";
@Component({
	moduleId: module.id,
	selector: "sis-time-picker-minutes",
	templateUrl: "sis-time-picker-minutes.component.html",
	providers: [DATE_INPUT_VALUE_ACCESSOR]
})
export class TimePickerMinutesComponent implements OnInit, ControlValueAccessor {


	/**
	 * Id for time view popup
	 * @type {string}
	 * @member {string}
	 * @public
	 */
	@Input()
	public id: string;

	@Input()
	public ampm: boolean = false;

	//Placeholders for the callbacks
	private _onTouchedCallback: () => {};
	private _onChangeCallback: (_: any) => {};

	private _time: number = 0;
	public focused: string = null;

	public timeOptions: {label: string, value: number}[];

	ngOnInit() {
		this.createTimeOptions(this.ampm, 30);
	}

	//From ControlValueAccessor interface
	writeValue(time: number) {
		this._time = time || 0;
	}

	//From ControlValueAccessor interface
	registerOnChange(callbackFunction: any) {
		this._onChangeCallback = callbackFunction;
	}

	//From ControlValueAccessor interface
	registerOnTouched(callbackFunction: any) {
		this._onTouchedCallback = callbackFunction;
	}

	public changeTime (diff: number) {
		this.time = this.time + diff;
	}

	public get time (): number {
		return this._time;
	}

	public set time (time: number) {
		this._time = (time + 1440) % 1440;
        this._onChangeCallback(this._time || 0);
	}

	public get hour (): string {
		let h: number = Math.floor(this.time / 60);
		if (this.ampm) {
			h = h % 12;
			h = h === 0 ? 12 : h;
		}
		return (100 + h).toString().substr(1, 2);
	}

	public setHour (el: HTMLInputElement) {
		this.setTime(parseInt(el.value, 10) || 0, null, null);
        el.value = this.hour;
	}

    public get minute (): string {
        return (100 + (this.time % 60)).toString().substr(1, 2);
    }

    public setMinute (el: HTMLInputElement) {
        this.setTime(null, parseInt(el.value, 10) || 0, null);
        el.value = this.minute;
	}

    public get period (): string {
        return this.time < 720 ? 'AM' : 'PM';
    }

    public setPeriod (el: HTMLInputElement) {
		this.setTime(null, null, (el.value || '').toLowerCase().includes('p'));
        el.value = this.period;
	}

	private setTime (hour: number = null, minute: number, pm: boolean = null) {
		let h: number = Math.floor(this._time / 60) || 0;
		let m: number = (this._time % 60) || 0;
		let p: boolean = this._time >= 720;

		if (hour !== null) {
			h = this.ampm && hour === 12 ? (p ? 12 : 0) : hour % 24;
		}
		if (minute !== null) {
			m = minute % 60;
		}
		if (pm !== null) {
			h = h % 12 + (pm ? 12 : 0);
		}
		this.time = h * 60 + m;
	}

	public isFocused (focused: string = null) {
		this.focused = focused
	}

    public onScroll(scrollEvent: WheelEvent, value: number): void {
        // We getting vertical scroll amount from detail, as deltaY property of wheel event isn't available in Mozilla.
        const verticalScrollAmount = scrollEvent.deltaY || scrollEvent.detail;
        this.changeTime(verticalScrollAmount > 0 ? -value : value);
    }

    private createTimeOptions(ampm: boolean, step: number) {
        this.timeOptions = [];
        for (let i = 0; i < 1440; i += step) {
            let t: number = ampm ? i % 720 : i;
            let h: number = Math.floor(t / 60);
            let m: number = t % 60;
            h = ampm && h === 0 ? 12 : h;
            this.timeOptions.push({
                label: Math.floor(h + 100).toString().substr(1, 2) + ':' + (m + 100).toString().substr(1, 2) + (ampm ? (i < 720 ? ' AM' : ' PM') : ''),
                value: i
            });
        }
    }
}
