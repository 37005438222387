import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {PreconditionCheck} from "@synisys/idm-common-util-frontend";
import {ComboboxOption} from "./combobox-option";

/**
 * Generic container to accommodate data required any combo-box option: the key, value and optionally the grouping
 * attributes.
 * @see MultilingualString
 */
export class ComboboxOptionWithColor<T1, T2 extends string | MultilingualString | Map<number, string>> extends ComboboxOption<any, any> {

    /**
     * Constructs the option with provided properties.
     * @param {T1} value
     * @param {T2} name
     * @param {string} color
     */
    constructor(public value: T1, public name: T2, public color: string) {
        super(value, name);
        PreconditionCheck.notNullOrUndefined(value);
        PreconditionCheck.notNullOrUndefined(name);
        PreconditionCheck.notNullOrUndefined(color);
    };

}
