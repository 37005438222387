/**
 * Created by hovhannes.ghurshudya on 9/12/2016.
 */

import {Component} from "@angular/core";
import {BaseTabsComponent} from "./sis-base-tabs.component";

/**
 * Represents the tab panel control for content navigation
 * @class
 */
import './sis-tabs.component.css'
     @Component({
	moduleId: module.id,
    selector: "sis-tabs",
    templateUrl: "sis-tabs.component.html"
})
export class TabsComponent extends BaseTabsComponent {


}
