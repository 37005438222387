import { Component, Input, Output, EventEmitter , ContentChild, ContentChildren, QueryList } from '@angular/core';
import {ListSorting} from './sis-list-sorting.component';

import "./sis-list-sorting-button-asc.component.css";  @Component({
    moduleId: module.id,
    selector: 'sis-list-sorting-button-asc',
    templateUrl: "sis-list-sorting-button-asc.component.html"
})

export class SortingButtonAsc{

    private _sortField: string;
    private _parent: ListSorting ;

    @Input('parent')
    public set parent(parent : ListSorting){
        this._parent = parent;
    }


    @Input('sortField')
    public set sortField(sortField:string){
        this._sortField = sortField;
    }

    public get sortField(){
        return this._sortField;
    }

    public getCurrentSortField() : string{
        return this._parent.sortField;
    }

    public isAscending() : boolean{
        return this._parent.isAscending;
    }


}
