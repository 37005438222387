/**
 * Created by Anushavan.Hovsepyan
 * on 9/19/2016.
 */
import {Component, AfterContentInit, Input, Output, EventEmitter} from "@angular/core";
import {RangeControlType} from "./range-control-type.model";

import "./sis-range.component.css";  @Component({
    moduleId: module.id,
    selector: "sis-range",
    templateUrl: "sis-range.component.html",
})

/**
 * @deprecated use 2 separate number inputs or datepickers instead.
 * Range control.
 * Gets range of values, start and end values,
 * validates inputs and output them.
 * Inputs type must be number or date.
 */
export class Range implements AfterContentInit {

    private static START_VALUE_FIELD_ID: number = 1;
    private static END_VALUE_FIELD_ID: number = 2;

    /**
     * Acceptable types for Range control.
     * @type {RangeControlType}
     */
    public rangeControlTypes = RangeControlType;

    /**
     * Range control messages. Will be used in validation.
     * @type {any}
     */
    @Input()
    public messages: any;

    /**
     * Range control id. Will be used in selenium testing.
     * @type {number}
     */
    @Input()
    public id: string;

    @Input()
    public disabled: boolean = false;

    /**
     * Range control type.
     * @type {RangeControlType}
     */
    @Input()
    public rangeControlType: RangeControlType;

    /**
     * Minimum of acceptable values.
     * @type {any}
     */
    @Input()
    public minValue: any = null;

    /**
     * Maximum of acceptable values.
     * @type {any}
     */
    @Input()
    public maxValue: any = null;

    /**
     * Start of range.
     * @type {any}
     * @public
     */
    @Input()
    public startValue: any = null;

    /**
     * End of range.
     * @type {any}
     * @public
     */
    @Input()
    public endValue: any = null;

    /**
     * Property for output selected fields values.
     * @type {any}
     */
    @Output()
    public onChange = new EventEmitter();

    /**
     * Inputs are true or not.
     * @type {boolean}
     * @public
     */
    public isValid: boolean = true;

    /**
     * Validation message, which will be shown in wrong inputs.
     * @type {string}
     * @public
     */
    public validationMessage: string = "";

    constructor() {

    }

    /**
     * After initializing values validates types of range.
     */
    public ngAfterContentInit(): any {
        switch (this.rangeControlType) {
            case this.rangeControlTypes.NUMBER: {
                if (!this.isValuesFromNumberType([this.minValue, this.maxValue])) {
                    throw TypeError("Range values must be number.");
                }
                break;
            }
            case this.rangeControlTypes.DATE: {
                if (!this.isValuesInstanceOfDate([this.minValue, this.maxValue])) {
                    throw TypeError("Range values must be Date.");
                }
                break;
            }
            default: {
                throw TypeError("Wrong range control type.");
            }
        }
    }

    /**
     * Checks minValue and maxValue types number or not.
     * values {Array<any>} - values which must checks.
     * @returns {boolean}
     */
    private isValuesFromNumberType(values: any[]): boolean {
       return values.every((value: any) => {
               return (value !== undefined && !isNaN(value) && typeof value === "number");
        });
    }

    /**
     * Checks minValue and maxValue are instance of Date or not.
     * values {Array<any>} - values which must checks.
     * @returns {boolean}
     */
    private isValuesInstanceOfDate(values: any[]): boolean {
        return values.every((value: any) => {
            return (value !== undefined && value instanceof Date);
        });
    }

    /**
     * On start value change calls value change action.
     */
    public onStartValueChange() {
        this.onValueChange(Range.START_VALUE_FIELD_ID);
    }

    /**
     * On end value change calls value change action.
     */
    public onEndValueChange() {
        this.onValueChange(Range.END_VALUE_FIELD_ID);
    }

    /**
     * On value change validates values, sets warning message.
     * If inputs are true, outputs selected values.
     * @param {number} fieldId - Field id, which was changed.
     */
    public onValueChange(fieldId: number): void {
        if (this.rangeControlType === this.rangeControlTypes.DATE) {
            this.minValue && this.minValue.setHours(0, 0, 0, 0);
            this.maxValue && this.maxValue.setHours(0, 0, 0, 0);
        }

        this.onChange.emit({
            startValue: this.startValue,
            endValue: this.endValue,
            fieldId,
        });
    }
}
