import {Injectable} from "@angular/core";
import {CookieService} from "@synisys/idm-authentication-client-js";

@Injectable()
export class CopyLanguageCacheService {

    /**
     * Cookie name storing the Copy Language Cache.
     * Cookie value structure: 'curLangId=x;y,z...', where curLangId is the system language id for which
     * the values were cached, and 'y,z...' are ids of copy languages.
     * @type {string}
     */
    public readonly COPY_LANGUAGES_COOKIE: string = 'copyLangCache';

    constructor(private cookieService: CookieService){
    }

    public getCachedLanguageIds(currentLangId: number): number[] {
        if (!this.hasCopyLanguageCache()) {
            return [];
        }

        const cachedValue: string[] = this.cookieService.get(this.COPY_LANGUAGES_COOKIE).split(';');
        const cacheLangId = Number(cachedValue[0].split('=').pop());

        if (cacheLangId !== currentLangId) {
            this.clearCopyCache();
            return [];

        } else {
            return cachedValue[1] ? cachedValue[1].split(',')
                .map(id => Number(id.trim()))
                .filter(id => !isNaN(Number(id))) : [];
        }
    }

    public cacheLanguageIds(currentLangId: number, ids: number[]): void {
        this.cookieService.set(this.COPY_LANGUAGES_COOKIE, `curLangId=${currentLangId};${ids.join(',')}`);
    }

    public hasCopyLanguageCache(): boolean {
        return !!this.cookieService.get(this.COPY_LANGUAGES_COOKIE);
    }

    public clearCopyCache(): void {
        this.cookieService.delete(this.COPY_LANGUAGES_COOKIE);
    }
}
