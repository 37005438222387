import {AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList} from '@angular/core';
import {SisSelectOptionComponent} from '../sis-select-option/sis-select-option.component';

@Component({
	moduleId: module.id,
	selector: 'sis-select-group-option',
	template: `<ng-content></ng-content>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SisSelectGroupOptionComponent implements AfterViewInit {

	/**
	 * Label to be shown as view name
	 */
	@Input() label: string;

	/**
	 * Class to be applied in group
	 */
	@Input() className: string;

	/**
	 * Flag to indicate whether empty groups are displayed or not.
	 */
	@Input() displayEmpty: boolean;

	/**
	 * Select items which will be converted to actual options list
	 */
	@ContentChildren(SisSelectOptionComponent) private selectItems: QueryList<SisSelectOptionComponent>;

	/**
	 * Holds the current/actual options which are shown in the view, it can be modified (e.g. filtered)
	 */
	options: Array<SisSelectOptionComponent>;

	constructor() {
		this.displayEmpty = false;
		this.options = [];
	}

	ngAfterViewInit(): void {
		this.options.push(...this.selectItems.toArray());
	}

	get isEmpty() {
		return this.options.length === 0;
	}
}
