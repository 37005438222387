import {AfterContentChecked, AfterContentInit, Component, ContentChildren, Input, QueryList} from "@angular/core";
import {TreeNodeExpand} from "./sis-tree-node-expand.component";
import {TreeNodeCollapse} from "./sis-tree-node-collapse.component";
import {TreeNodeExpandCollapse} from "./sis-tree-node-expand-collapse.component";

import "./sis-tree-node.component.css";
@Component({
	moduleId: module.id,
	selector: "sis-tree-node",
	templateUrl: "sis-tree-node.component.html",
})

/**
 * TreeNode component provides the user to give custom templates to {@link Tree}.
 * This component represents the nodes of the tree and adds expand/collapse functionality to that nodes.
 * The design for expand/collapse icons can be provided via the following component {@link TreeNodeExpandCollapse}
 */
export class TreeNode implements AfterContentInit, AfterContentChecked {

	/**
	 * Describes if the node is expanded.
	 * @type {boolean}
	 */
	@Input()
	public isExpanded: boolean = false;


	/**
	 * Describes the parent node.
	 * @type {TreeNode}
	 */
	private _parent: TreeNode = null;

	private _parent2: TreeNode = null;

	/**
	 * The list of child tree nodes contained in the template of the node.
	 */
	@ContentChildren(TreeNode)
	public childNodes: QueryList<TreeNode>;


	/**
	 * The list of expand child nodes contained in the template of the node.
	 */
	@ContentChildren(TreeNodeExpand)
	public childExpandNodes: QueryList<TreeNodeExpand>;

	/**
	 * The list of collapse child nodes contained in the template of the node.
	 */
	@ContentChildren(TreeNodeCollapse)
	public childCollapseNodes: QueryList<TreeNodeCollapse>;

	/**
	 * The list of expand/collapse child nodes contained in the template of the node.
	 */
	@ContentChildren(TreeNodeExpandCollapse)
	public childExpandCollapseNodes: QueryList<TreeNodeExpandCollapse>;

	constructor() {

	}

	@Input()
	public set parent(parent: TreeNode) {
		this._parent2 = parent;
		Promise.resolve(() => {
		}).then();
	}

	public get parent() {
		return this._parent;
	}

	public ngAfterContentInit() {
		this._initChildren();

	}

	ngAfterContentChecked(): void {
		this._parent = this._parent2;
	}

	/**
	 * Adds the parent node of all the child nodes.
	 * @private
	 */
	private _initChildren() {
		this.childNodes.forEach((node, index) => {
			if (index > 0) node.parent = this;
		});
		this.childExpandNodes.forEach((expand) => {
			expand.parent = this;
		});
		this.childCollapseNodes.forEach((collapse) => {
			collapse.parent = this;
		});
		this.childExpandCollapseNodes.forEach((expandCollapse) => {
			expandCollapse.parent = this;
		});
	}

	/**
	 * Expands/Collapses the nodes.
	 */
	public toggleExpand() {
		this.isExpanded = !this.isExpanded;
	}
}
