import {Component} from "@angular/core";

import "./sis-tree.component.css";  @Component({
    moduleId: module.id,
    selector: "sis-tree",
    templateUrl: "sis-tree.component.html"
})
/**
 * Tree component which enables the users to render tree with the provided custom templates.
 * The nodes of this tree should be provided via {@link TreeNode}
 */
export class Tree {
    constructor() {

    }
}
