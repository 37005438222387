import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'sis-select-option',
	template: `<ng-content></ng-content>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SisSelectOptionComponent {

	/**
	 * Value to be passed to model
	 */
	@Input() value: any;

	/**
	 * Label to be shown as view name
	 */
	@Input() label: string;

	/**
	 * Class to be applied in option
	 */
	@Input() className: string;

	constructor() {
	}
}
