import {Directive, HostListener} from '@angular/core';

@Directive({
	selector: '[number-input]',
})
export class NumberInputDirective {
	private readonly keysOfNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
	private readonly cKeyCode = 67;
	private readonly aKeyCode = 65;
	private readonly xKeyCode = 88;
	private readonly arrowRightKeyCode = 39;
	private readonly arrowLeftKeyCode = 37;
	private readonly backspaceKeyCode = 8;

	@HostListener('keydown', ['$event'])
	onNgModelChange(keyDownEvent: KeyboardEvent) {
		const which: number = keyDownEvent.which;
		const isPressedCtrlCOrCtrlXOrCtrlA = keyDownEvent.ctrlKey && (which == this.cKeyCode || which == this.xKeyCode || which == this.aKeyCode);
		const isPressedNumber = this.keysOfNumbers.indexOf(keyDownEvent.key) !== -1;
		const isPressedArrowRight = which === this.arrowRightKeyCode;
		const isPressedArrowLeft = which === this.arrowLeftKeyCode;
		const isPressedBackspace = which === this.backspaceKeyCode;
		if (!isPressedBackspace && !isPressedArrowRight && !isPressedArrowLeft && !isPressedNumber &&
			!isPressedCtrlCOrCtrlXOrCtrlA && !keyDownEvent.ctrlKey && !keyDownEvent.shiftKey) {
			keyDownEvent.preventDefault();
		}
	}
}