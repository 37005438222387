import {Component} from "@angular/core";

import "./sis-tree-node-collapse.component.css";
import {TreeNode}  from "./sis-tree-node.component";

@Component({
               moduleId   : module.id,
               selector   : 'sis-tree-node-collapse',
               templateUrl: 'sis-tree-node-collapse.component.html',
           })

/**
 * TreeNodeCollapse enables the users to collapse the nodes and to provide the design of collapse node.
 */
export class TreeNodeCollapse {

    /**
     * The node that contains the collapse icon. TThis node would be collapsed when the collapse node is clicked.
     * @type {TreeNode}
     * @private
     */
    private _parent: TreeNode = null;

    constructor() {

    }

    public get parent() {
        return this._parent;
    }

    public set parent(parent: TreeNode) {
        this._parent = parent;
    }

}
