/**
 * Created by nina.kirakosyan on 11/18/2020.
 */
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input, OnInit, Optional,
    Output,
    TemplateRef
} from '@angular/core';
import {SisTooltipPosition, SisTooltipSize} from "./tooltip-configs.model";
import './sis-ng-tooltip.component.css';
import {ControlsTranslationService} from "../shared/controls-translation.service";
import {Observable} from "rxjs/Observable";

/**
 * Tooltip component with simple text or dynamic content via template - generated from sisTooltip directive.
 */
@Component({
    moduleId   : module.id,
    selector   : 'sis-ng-tooltip',
    templateUrl: 'sis-ng-tooltip.component.html',
})
export class SisNgTooltipComponent implements OnInit {

    /**
     * Unique id for control. Mainly is used for automated testing.
     * @type {string}
     */
    @Input() public id: string;

    /**
     * The message to be displayed in the tooltip.
     * @type {string}
     */
    @Input() public text: string;

    /**
     * Template reference to by displayed inside the tooltip.
     * @type {TemplateRef<any>}
     */
    @Input() public contentTemplate: TemplateRef<any>;

    /**
     * Disables the display of the tooltip.
     * @type {boolean}
     */
    @Input() public disabled: boolean;

    /**
     * Tooltip title.
     * @type {string}
     */
    @Input() public title: string;

    /**
     * Tooltip popup built-in checkbox text.
     * @type {string}
     */
    @Input() public checkboxText: string;

    /**
     * Tooltip popup built-in button text.
     * @type {string}
     */
    @Input() public buttonText: string;

    /**
     * Flag to draw tooltip title.
     * @type {boolean}
     */
    @Input() public showTitle: boolean = false;

    /**
     * Flag to show tooltip "don't show again" checkbox.
     * @type {boolean}
     */
    @Input() public showDisableCheckbox: boolean = false;

    /**
     * Flag to show button to close tooltip from inside..
     * @type {boolean}
     */
    @Input() public showCloseButton: boolean = false;

    /**
     * Size option for the tooltip
     * @type {SisTooltipSize}
     */
    @Input() public size: SisTooltipSize;

    /**
     * Placement option setter
     * @type {string}
     */
    @Input() public set placementType(val: any) {
        this.placement = val;
        this.chDetRef.detectChanges();
    }

    /**
     * Classes to be passed to the tooltip. Supports the same syntax as ngClass.
     */
    @Input() tooltipClass: string = '';

    /**
     * Emits the change of hovering when mouse entering/leaving the tooltip.
     * @type {EventEmitter<boolean>}
     */
    @Output() public onHoverChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Emits the change of checkbox value.
     * @type {EventEmitter<boolean>}
     */
    @Output() public onCheckboxClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Emits the close tooltip button click.
     * @type {EventEmitter<boolean>}
     */
    @Output() public onCloseAction: EventEmitter<boolean> = new EventEmitter<any>();

    /**
     * Tooltip Mouse Enter event.
     */
    @HostListener('mouseenter') enter(): void {
        this.onHoverChange.emit(true);
    }

    /**
     * Tooltip Mouse Leave event.
     */
    @HostListener('mouseleave') leave(): void {
        this.onHoverChange.emit(false);
    }

    /**
     * Placement option for the tooltip.
     */
    public placement: any;

    /**
     * Default title for the tooltip.
     */
    public defaultTitle: string = 'Tips and tricks';

    /**
     * SisTooltipPosition class holder for tooltip positioning strategy configs.
     */
    public strategy = SisTooltipPosition;

    ngOnInit(): void {
        if (this.size == 'x-small' || this.size == 'small' || this.size == 'medium') {
            this.showTitle = false;
            this.showDisableCheckbox = false;
            this.showCloseButton = false;
        }
        if (this.size == 'x-small') {
            this.contentTemplate = null;
        }
    }

    constructor(@Optional() private translationService: ControlsTranslationService,
                private chDetRef: ChangeDetectorRef) {
    }

    public onCheckToggle(value: boolean): void {
        this.onCheckboxClicked.emit(value);
    }

    public getMessage (key: string): Observable<string> {
        return this.translationService && this.translationService.getMessage(key);
    }

}
