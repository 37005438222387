import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from "@angular/core";
import {MatInput, MatSelect} from "@angular/material";
import {Subscription} from "rxjs/Subscription";
import {ComboboxGroup, ComboboxOption, ComboboxOptions, ComboboxOptionWithColor} from "./shared/index";

/**
 * Reporting combo box component is for reporting controls to draw paths and paths with mapped colors (if any exists).
 */
import "./reporting-combobox.component.css";
@Component({
    moduleId: module.id,
    selector: "sis-reporting-combobox",
    templateUrl: "reporting-combobox.component.html"
})
export class ReportingComboboxComponent implements AfterViewInit, OnDestroy {

    private _groupedData: ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOptionWithColor<any, any> | ComboboxOption<any, any>>, any>>;

    private _selectedValue: any;

    @Input()
    public id: string;

    @Input()
    public hasColor: boolean;

    @Input()
    public placeHolder: string;

    /**
     * This is id of combo option which should have id and name at least for displaying right data.
     */
    @Input('value')
    public value: number;


    @Input()
    public showItemsLimit: number = 0;

    /**
     * Grouped data, which is path oriented, so it should contain groups of paths. For more see type of data.
     * This data can also contain paths with mapped colors.
     * @param data
     */
    @Input()
    public set groupedData(data: ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOptionWithColor<any, any> | ComboboxOption<any, any>>, any>>) {
        this._groupedData = data ? data : [];
        this.processedData = this.filterGroups('');
        this._selectedValue = this.findItem(this.value);
    }

    public get groupedData() {
        return this._groupedData;
    }

    /**
     * Transfer selected value. It returns combo option which should have at least id and name.
     * @type {EventEmitter<any>}
     */
    @Output()
    public valueChange: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Triggers when selection panel get closed
     * @type {EventEmitter}
     */
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(MatSelect) private select: MatSelect;

    @ViewChild(MatInput) private searchInput: MatInput;

    public processedData: ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOptionWithColor<any, any> | ComboboxOption<any, any>>, any>> = [];

    private openedChangeSubscription: Subscription;
    public search: any;


    public get selectedValue(): any {
        return this._selectedValue;
    }

    public set selectedValue(value: any) {
        this._selectedValue = value;
        this.valueChange.emit(this._selectedValue);

        // for reset mat-select selected value
        this._selectedValue = null;
    }

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.select.open();
        this.changeDetectorRef.detectChanges();

        this.openedChangeSubscription = this.select.openedChange.asObservable().subscribe(opened => {
            opened && this.searchInput.focus();
            !opened && this.onClose.emit(true);
        });
    }


    ngOnDestroy(): void {
        if (this.openedChangeSubscription) {
            this.openedChangeSubscription.unsubscribe();
        }
    }

    displayFn(value: any): string {
        return value && typeof value === 'object' ? value.name : value;
    }

    public filterGroups(name?: any) {
        let itemName: string = this.displayFn(name);
        itemName = itemName || '';

        return this.groupedData.map((group) => {
            let groupLimitCounter: number = 0;
            return {
                name: group.name,
                items: group.items.filter((item: any) => {
                    let ret = (!this.showItemsLimit || groupLimitCounter < this.showItemsLimit) && item.name.toLowerCase().indexOf(itemName.toLowerCase()) !== -1;
                    if (ret) {
                        groupLimitCounter++;
                    }
                    return ret;
                })
            }
        }).filter((group) => {
            return group.items.length > 0;
        });
    }

    private findItem(value: number): any {
        let foundItem: any = this.processedData.find((group: any) => {
            return group.items.find((item: any) => {
                return item.value.id === value;
            })
        });

        return foundItem ? foundItem.items.find((item: any) => {
            return item.value.id === value;
        }) : null;
    }

}
