export class DataModel {
    constructor(public isGroup: boolean,
                public groupIndex: number,     // undefined if not in a group
                public label: string,
                public className: string = '',
                public value: string = '') {
        this.isGroup = isGroup;
        this.groupIndex = groupIndex;
        this.label = label;
        this.className = className;
        this.value = value;
    }
}