import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {PreconditionCheck} from "@synisys/idm-common-util-frontend";
import {ComboboxOption} from "./combobox-option";
import {ComboboxOptions} from "./combobox-options";

export class ComboboxGroup<T1 extends ComboboxOption<any, any> | ComboboxOptions<any>, T2 extends string | MultilingualString | Map<number, string>> {

    /**
     * Constructs the option with provided properties.
     * @param {T1} items
     * @param {T2} name
     */
    constructor(public items: T1, public name: T2) {
        PreconditionCheck.notNullOrUndefined(items);
        PreconditionCheck.notNullOrUndefined(name);
    };

}
