/**
 * Created by Anushavan.Hovsepyan
 * on 9/19/2016.
 */
import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import './sis-tag.component.css';

@Component({
    moduleId: module.id,
    selector: "sis-tag",
    templateUrl: "sis-tag.component.html",
    encapsulation: ViewEncapsulation.None
})

/**
 * Tag control, which get tag id and label, and show it.
 * Tag control is deletable.
 */
export class Tag {

    /**
     * Tag id.
     * @type {number}
     */
    @Input()
    public id: number;

    /**
     * Tag label.
     * @type {number}
     */
    @Input()
    public label: string;


    public isRemovable: boolean = true;

    /**
     * Tag details.
     * Each detail must contains 'label' and 'description' properties.
     * @type {Array<any>}
     */
    @Input()
    public details: any[];

    /**
     * Delete tag action.
     * @type {EventEmitter}
     */
    @Output()
    public tagDeleteAction = new EventEmitter();

    public onDeleteTag(id: number): void {
        this.tagDeleteAction.emit(id);
    }

    get seleniumTestClass() {
        return `${this.id}-tooltip`
    }

}
