/**
 * Created by hovhannes.ghurshudya on 9/12/2016.
 */

import {BaseTabsComponent} from "./sis-base-tabs.component";
import {Component, HostBinding} from "@angular/core";

/**
 * @deprecated use Material tabs instead.
 * @class
 */
import '././sis-tabs-main-navigation.component.css'
     @Component({
	moduleId: module.id,
    selector: "sis-tabs-main-navigation",
    templateUrl: "./sis-tabs-main-navigation.component.html"
})
export class MainNavigationTabsComponent extends BaseTabsComponent{

    // @HostBinding("attr.class")
    // public cssClass = "sis-tabs-main-navigation";
}
