import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	QueryList,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {MatInput, MatSelect, MatSelectChange} from '@angular/material';
import {SisSelectOptionComponent} from './sis-select-option/sis-select-option.component';
import {SisSelectGroupOptionComponent} from './sis-select-group-option/sis-select-group-option.component';
import {SisVirtualScrollViewportComponent} from '../virtual-scroller/virtual-scroll-viewport/sis-virtual-scroll-viewport.component';
import {SelectionChange} from './event/selection-change';
import {Subscription} from 'rxjs/Subscription';
import {DataModel} from './models/data.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';


import './sis-select.component.css';
@Component({
	moduleId: module.id,
	selector: 'sis-select',
	templateUrl: './sis-select.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SisSelectComponent implements AfterViewInit, OnDestroy {

	@Input()
	public id: string;

	/**
	 * Value of the select control.
	 */
	@Input() value: any;

	/**
	 * Placeholder of the select control.
	 */
	@Input() placeholder: string;

	/**
	 * Indicates whether select is searchable
	 */
	@Input() searchable: boolean;

	/**
	 * Indicates whether search input is autofocused
	 */
	@Input() autofocus: boolean;

	/**
	 * Indicates whether select should open automatically
	 */
	@Input() autoOpen: boolean = false;

	/**
	 * MatSelect component which functionality is wrapped under this select component
	 */
	@ViewChild(MatSelect) private select: MatSelect;

	/**
	 * MatSelect component reference is wrapped under this selectRef component
	 */
	@ViewChild(MatSelect, {read: ElementRef}) private selectRef: ElementRef;

	/**
	 * SisVirtualScrollViewportComponent component which functionality is wrapped under this select component
	 */
	@ViewChild(SisVirtualScrollViewportComponent) private viewPort: SisVirtualScrollViewportComponent;

	/**
	 *
	 */
	@ViewChild(MatInput) private searchInput: MatInput;

	/**
	 * Select items which will be converted to actual options list
	 */
	@ContentChildren(SisSelectOptionComponent) private selectItems: QueryList<SisSelectOptionComponent>;

	/**
	 * Group select items which will be converted to actual option groups list
	 */
	@ContentChildren(SisSelectGroupOptionComponent) private groupItems: QueryList<SisSelectGroupOptionComponent>;

	/**
	 * Holds the options based on the inner components
	 */
	private allItems: Array<DataModel> = [];

	/**
	 * Holds the current/actual options which are shown in the view, it can be modified (e.g. filtered)
 	 */
	public filteredItems: DataModel[] = [];

	/**
	 * Search term by which select should be filtered
	 */
	public searchTerm: string;

	/**
	 * Triggers on selection change
	 * @type {EventEmitter}
	 */
	@Output() change: EventEmitter<SelectionChange> = new EventEmitter();

	/**
	 * Triggers when selection panel get closed
	 * @type {EventEmitter}
	 */
	@Output() onClose: EventEmitter<boolean> = new EventEmitter();

	/**
	 * Triggers when selection panel opens
	 * @type {EventEmitter}
	 */
	@Output() onOpen: EventEmitter<boolean> = new EventEmitter();

	private _selectionChangeSubscription: Subscription;
	private _openCloseChangeSubscription: Subscription;
	private _unsubscribe$: Subject<void> = new Subject<void>();


	/**
	 * Holds the height of each select option height in pixels
	 */
	public readonly itemHeight: number = 39;

	private readonly  _defaultItemsOnViewport = 9;

	/**
	 * Holds the maximum amount of select options that are seen in DOM
	 */
	public itemsOnViewport: number = this._defaultItemsOnViewport;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
	}

	ngAfterViewInit(): void {
		if (this.isSelectItemsExist() || this.isGroupItemsExist()) {
			this.initiateItems();
		}

		this.selectItems.changes.pipe(takeUntil(this._unsubscribe$)).subscribe((r) => {
			this.initiateItems();
		});

		this.groupItems.changes.pipe(takeUntil(this._unsubscribe$)).subscribe((r) => {
			this.initiateItems();
		});

		this.itemsOnViewport = Math.min(this._defaultItemsOnViewport, this.filteredItems.length);

		if (this.autoOpen) {
			this.open();
		}

		this._selectionChangeSubscription = this.select.selectionChange.asObservable().subscribe((change: MatSelectChange) => {
			this.change.emit(new SelectionChange(change.value));
		});
		this._openCloseChangeSubscription = this.select.openedChange.asObservable().subscribe(opened => {
			if (opened) {
				this.onOpen.emit(true);
			} else {
				this.onClose.emit(true);
			}
		});
	}

	ngOnDestroy(): void {
		if (this._selectionChangeSubscription) {
			this._selectionChangeSubscription.unsubscribe();
		}
		if (this._openCloseChangeSubscription) {
			this._openCloseChangeSubscription.unsubscribe();
		}
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	/**
	 * Holds the element which scroll event must be listened
	 */
	get scrollContainer() {
		return (this.select.panel && this.select.panel.nativeElement) || this.selectRef.nativeElement;
	}

	public open(): void {
		const self = this;
		setTimeout(function () {
			self.select.open();
			setTimeout(function () {
				if(self.searchable && self.autofocus) {
					self.searchInput.focus();
				}
			})
		}, 0);
	}

	public close(): void {
		this.select.close();
	}

	public isPanelOpen(): boolean {
		return this.select.panelOpen;
	}

	public filter(): void {
		if (this.searchTerm) {
			const result: DataModel[] = [];
			const searchTermToLower = this.searchTerm.toLowerCase();
			let currentGroup: DataModel;
			for (const item of this.allItems) {
				if (item.isGroup) {
					currentGroup = item;
				}
				if (!item.isGroup && item.label.toLowerCase().indexOf(searchTermToLower) > -1) {
					const prevItem = result[result.length - 1];
					if (prevItem && prevItem.groupIndex !== item.groupIndex) {
						result.push(this.allItems[item.groupIndex]);
					} else if (currentGroup && result.findIndex((item: DataModel) => item.label === currentGroup.label) === -1) {
						result.push(currentGroup);
					}
					result.push(item);
				}
			}
			this.filteredItems = result;
		}
		else {
			this.reloadItems();
		}
	}

	public onSelectOpen(event: boolean): void {
		this.searchTerm = '';
		if (event) {
			this.viewPort.scrollToIndex(0);
			this.viewPort.checkViewportSize();
		}
	}

	public reloadItems(): void {
		this.filteredItems = this.allItems;
	}

	/**
	 * Fills items by using provided selectItems and groupItems
	 */
	private initiateItems(): void {
		this.allItems = [];
		for (const option of this.selectItems.toArray()) {
			this.allItems.push(
				new DataModel(false, undefined, option.label, option.className, option.value)
			);
		}
		for (const group of this.groupItems.toArray()) {
			const groupIndex = this.allItems.length;
			if (!group.isEmpty || group.displayEmpty) {
				this.allItems.push(
					new DataModel(true, undefined, group.label, group.className)
				);
			}
			for (const option of group.options) {
				this.allItems.push(
					new DataModel(false, groupIndex, option.label, option.className, option.value)
				);
			}
		}
		this.filteredItems = this.allItems;
	}

	private isSelectItemsExist(): boolean {
		return this.selectItems && this.selectItems.length > 0;
	}

	private isGroupItemsExist(): boolean {
		return this.groupItems && this.groupItems.length > 0;
	}
}
