import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {PreconditionCheck} from "@synisys/idm-common-util-frontend";

/**
 * Generic container to accommodate data required any combo-box option: the key, value and optionally the grouping
 * attributes.
 * @see MultilingualString
 */
export class ComboboxOption<T1, T2 extends string | MultilingualString | Map<number, string>> {

    /**
     * Constructs the option with provided properties.
     * @param {!T1} value - The identifying value of the option.
     * @param {!T2} name - The display value (human readable).
     * @param {string} [group=null] - Options with identical value should be visually grouped.
     */
    constructor(public value: T1, public name: T2, public group: string = null) {
        PreconditionCheck.notNullOrUndefined(value);
        PreconditionCheck.notNullOrUndefined(name);
    };

}
