import {Component, Input, AfterContentInit, ContentChildren, QueryList, OnChanges, SimpleChange} from '@angular/core';
import {Paging} from './sis-paging.component';

import "./sis-list-paging.component.css";  @Component({
    moduleId: module.id,
    selector: 'sis-list-paging',
    templateUrl: "sis-list-paging.component.html"
})

export class ListPaging implements AfterContentInit, OnChanges {

    private _items: Array<Object>;
    private _pageItems: SubArray<Object>;
    private _page: number = 0;
    private _pageSize: number = 0;

    @ContentChildren(Paging) pagingElements: QueryList<Paging>;

    @Input('pageSize')
    public set pageSize(pageSize: number) {
        this._pageSize = pageSize;
        this._initPageItems();
    }

    @Input('items')
    public set items(items: Array<Object>) {
        this._page = 0;
        this._items = items;
        this._initPageItems();
    }

    public get items() {
        return this._items;
    }

    @Input('page')
    public set page(page: number) {
        this._page = page;
        this._initPageItems();
    }

    public get page() {
        return this._page;
    }

    ngAfterContentInit() {
        this.pagingElements.forEach((paging, index)=> {
            paging.pageSize = this._pageSize;
            paging.totalPageCount = this._getTotalPageCount();
            paging.itemsCount = this._items.length;
        });
    }

    ngOnChanges(changes : {[propKey:string]: SimpleChange}){
        changes['items'] && (this.items = changes['items'].currentValue);
    }

    public get pageItems() {
        return this._pageItems;
    }

    private _getTotalPageCount(): number {
        return Math.floor(this._items.length / this._pageSize) + (this._items.length % this._pageSize == 0 ? 0 : 1);
    }

    private _initPageItems() {
        if(this._items !== undefined && this._pageSize !== undefined && this._page !== undefined){
            let start = this._page * this._pageSize;
            let end = start + this._pageSize - 1;
            if (end > this._items.length - 1) {
                end = this._items.length - 1;
            }
            this._pageItems = new SubArray(this._items, start, end);
            this.pagingElements && this.pagingElements.forEach((paging)=> {
                paging.pageSize = this._pageSize;
                paging.totalPageCount = this._getTotalPageCount();
                paging.itemsCount = this._items.length;
            });
        }
    }

}

export class SubArrayIterator<T> implements Iterator<T> {
    private index: number;

    constructor(private array: any, private start: number, private end: number) {
        this.index = start;
    }

    public next(): IteratorResult<T> {
        if (this.index <= this.end) {
            return {
                done: false,
                value: this.array[this.index++]
            }
        }
        else {
            return {
                done: true,
                value: undefined
            }
        }
    }
}
export class SubArray<T> implements Iterable<T> {

    public start: number;
    public end: number;

    constructor(private array: any, start: number, end: number) {
        this.start = start;
        this.end = end;
    }

    [Symbol.iterator]() {
        return new SubArrayIterator<T>(this.array, this.start, this.end);
    }

}
