import {Subscription} from "rxjs/Subscription";

let SubscriptionManagerHandler = {
    set: function (target: any, name: string, value: any) {
        target[name] && target[name].unsubscribe && !target[name].closed && target[name].unsubscribe();
        target[name] = value;
        return true;
    },
    get: function (target: any, name: string) {
        return target[name];
    }
};

class SubscriptionManagerHelper {
    private subList: any[];
    private subNames: any;
    private subProxy: any;

    public addSubscription (subscription: Subscription) {
        this.subList = this.subList || [];
        this.subList.push(subscription);
    }

    public registerSubscription () {
        this.subNames = this.subNames || {};
        this.subProxy = this.subProxy || new Proxy(this.subNames, SubscriptionManagerHandler);
        return this.subProxy;
    }

    public cleanup () {
        this.subList && this.subList.forEach( (s: Subscription) => {
            s && s.unsubscribe && !s.closed && s.unsubscribe();
        });
        if (this.subNames) {
            for (const key in this.subNames) {
                if (this.subNames.hasOwnProperty(key)) {
                    let s: Subscription = this.subNames[key];
                    s && s.unsubscribe && !s.closed && s.unsubscribe();
                }
            }
        }
    }
}

export function SubscriptionManager(config: any = null) {
    return function (target: any) {
        Object.defineProperty(target.prototype, (config && config.name) || '$sm', {
            set: function (subscription: Subscription) {
                this.$ngSubscriptionManager = this.$ngSubscriptionManager || new SubscriptionManagerHelper();
                this.$ngSubscriptionManager.addSubscription(subscription);
            },
            get: function () {
                this.$ngSubscriptionManager = this.$ngSubscriptionManager || new SubscriptionManagerHelper();
                return this.$ngSubscriptionManager.registerSubscription();
            }
        });

        let ngOnDestroy = target.prototype.ngOnDestroy;
        Object.defineProperty(target.prototype, 'ngOnDestroy', {
            value: function () {
                ngOnDestroy && ngOnDestroy.call(this);
                this.$ngSubscriptionManager && this.$ngSubscriptionManager.cleanup();
            }
        });
    }
}

export interface SubscriptionManager {
    [key: string]: any;
}