/**
 * Created by Anushavan.Hovsepyan on 10/27/2016.
 */
import {Directive, OnChanges, Input, SimpleChanges} from "@angular/core";


/**
 * SelectItemDirective for initializing list options (ComboBox, SelectList, Menu etc.).
 * Gets as input:
 *      data - The array of objects, which contains options data.
 *      value - The option value.
 *      name - The option name.
 *      group - The group of item.
 *      isEnabled - If item is enabled.
 *      valueField - The value field name.
 *      nameField - The name field name.
 *      groupField - The criteria name of grouping.
 *      isEnabledField - The enabled field name.
 * The valueField, the nameField, the groupField and the isEnabledField can be used only with data.
 */
@Directive({
    selector: "sis-select-item, sis-select-items"
})
export class SelectItemDirective implements OnChanges {

    /**
     * The parent component.
     * @type {any}
     */
    public parent: any;

    /**
     * The array of objects, which contains options data.
     * @type {Array<any>}
     */
    @Input()
    public data: Array<any>;

    /**
     * The option value.
     * @type {any}
     */
    @Input()
    public value: any;

    /**
     * The name of item.
     */
    @Input()
    public name: string;

    /**
     * The group of item.
     */
    @Input()
    public group: string;

    /**
     * The property of menu item, determining is it enabled.
     * @type {boolean}
     */
    @Input()
    public isEnabled: boolean = true;

    /**
     * Name of value field.
     */
    @Input()
    public valueField: string;

    /**
     * Name of name field.
     */
    @Input()
    public nameField: string;

    /**
     * Name of grouping field.
     */
    @Input()
    public groupField: string;

    /**
     * Name of enabled field.
     */
    @Input()
    public isEnabledField: string;

    /**
     * Updates the items.
     * @param {SimpleChanges} changes - The map of component changed input fields.
     */
    ngOnChanges(changes: SimpleChanges): void {
        let currentData = changes['data'];
        let currentValue = changes['value'];
        let currentName = changes['name'];
        let currentGroup = changes['group'];
        let currentIsEnabled = changes['isEnabled'];
        let currentGroupField = changes['groupField'];

        if (currentData || currentValue || currentName
            || currentGroup || currentIsEnabled || currentGroupField) {
            this.parent && this.parent.onItemsChanged();
        }
    }
}