export class RadioButtonItem {

    constructor(public name: string, public id: number|string) {
    }

    public getId(): number|string {
        return this.id;
    }

    public setId(id: number|string): void {
        this.id = id;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }
}
