/**
 * Created by nina.kirakosyan on 11/18/2020.
 */
import {
    ComponentRef,
    Directive,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef
} from '@angular/core';
import {
    ConnectionPositionPair,
    Overlay,
    OverlayPositionBuilder,
    OverlayRef
} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {SisNgTooltipComponent} from './sis-ng-tooltip.component';
import {SisTooltipPlacement, SisTooltipPosition, SisTooltipSize} from './tooltip-configs.model';

/**
 * Custom sis tooltip-popup directive for displaying texts or dynamic interactive content on hover.
 */
@Directive({
    selector: '[sisTooltip]'
})
export class SisTooltipDirective implements OnInit, OnDestroy {

    /**
     * The message to be displayed in the tooltip.
     * @type {string}
     */
    @Input('sisTooltip') text: string = '';

    /**
     * Template reference to be displayed inside the tooltip.
     * @type {TemplateRef<any>}
     */
    @Input('sisTooltipTemplateRef') contentTemplate: TemplateRef<any>;

    /**
     * Disables the display of the tooltip.
     * @type {boolean}
     */
    @Input('sisTooltipDisabled') disabled: boolean = false;

    /**
     * Whether the tooltip works on click vs on hover.
     * @type {boolean}
     */
    @Input('sisTooltipOnClick') onClick: boolean = false;

    /**
     * Size option for the tooltip
     * @type {SisTooltipSize}
     */
    @Input('sisTooltipSize') size: SisTooltipSize = 'small';

    /**
     * Placement option for the tooltip
     * @type {SisTooltipPlacement}
     */
    @Input('sisTooltipPlacement') set placementType(value: SisTooltipPlacement) {
        this.placement = value;
        this.initTooltipOverlay();
    }

    public placement: SisTooltipPlacement = 'above';

    /**
     * Tooltip title.
     * @type {string}
     */
    @Input('sisTooltipTitle') public title: string;

    /**
     * Tooltip popup built-in checkbox text..
     * @type {string}
     */
    @Input('sisTooltipCheckboxText') public checkboxText: string;

    /**
     * Tooltip popup built-in button text.
     * @type {string}
     */
    @Input('sisTooltipButtonText') public buttonText: string;

    /**
     * Flag to draw tooltip title.
     * @type {boolean}
     */
    @Input('sisTooltipShowTitle') public showTitle: boolean = false;

    /**
     * Flag to show tooltip "don't show again" checkbox.
     * @type {boolean}
     */
    @Input('sisTooltipShowDisableCheck') public showDisableCheckbox: boolean = false;

    /**
     * Flag to show button to close tooltip from inside.
     * @type {boolean}
     */
    @Input('sisTooltipShowCloseButton') public showCloseButton: boolean = false;

    /**
     * Classes to be passed to the tooltip.
     */
    @Input('sisTooltipClass') tooltipClass: string;

    /**
     * Emits the change of checkbox value.
     * @type {EventEmitter<boolean>}
     */
    @Output('sisTooltipDisableCheck') onDisableCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Emits when the tooltip disappears.
     * @type {EventEmitter<boolean>}
     */
    @Output('sisTooltipClosed') onTooltipClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Overlay reference, to which the tooltip component is attached.
     * @type {OverlayRef}
     */
    private overlayRef: OverlayRef;

    /**
     * Tooltip component reference.
     * @type {ComponentRef<SisNgTooltipComponent>}
     */
    private tooltipRef: ComponentRef<SisNgTooltipComponent>;

    /**
     * Default positioning configs for the tooltip, as initially indicated.
     * May automatically change based on available surrounding space in window.
     * @type {ConnectionPositionPair}
     */
    private defaultPosition: ConnectionPositionPair = SisTooltipPosition.UP;

    /**
     * Shows whether the tooltip host is being hovered.
     * @type {boolean}
     */
    private isHostHovered: boolean = false;

    /**
     * Shows whether the tooltip component is being hovered.
     * @type {boolean}
     */
    private isTooltipHovered: boolean = false;

    /**
     * Shows whether the tooltip host has been clicked to open tooltip-popup.
     * @type {boolean}
     */
    private isClicked: boolean = false;

    constructor(private overlayPositionBuilder: OverlayPositionBuilder,
                private elementRef: ElementRef,
                private overlay: Overlay) {
    }

    /**
     * Mouse Enter event for the tooltip host.
     */
    @HostListener('mouseenter') onHoverEnter(): void {
        if (!this.onClick) {
            this.isHostHovered = true;
            this.show();
        }
    }

    /**
     * Mouse Leave event for the tooltip host.
     */
    @HostListener('mouseleave') onHoverLeave(): void {
        if (!this.onClick) {
            setTimeout(() => {
                this.isHostHovered = false;
                this.hide();
            }, 0);
        }
    }

    /**
     * Click-outside event for closing the tooltip.
     */
    @HostListener('document:click', ['$event']) clickedOutside($event: any) {
        if (this.onClick) {
            if (this.isClicked && !this.isTooltipHovered) {
                this.isClicked = false;
                this.hide();
            }
        }
    }

    /**
     * Click event for the tooltip host.
     */
    @HostListener('click', ['$event']) onHostClick($event: any): void {
        if (this.onClick) {
            this.onClickToggle();
            $event.stopPropagation();
        }
    }

    private onClickToggle(): void {
        if (this.onClick) {
            this.isClicked = !this.isClicked;

            if (this.isClicked) {
                this.show();
            }
            else {
                this.hide();
            }
        }
    }

    private show(): void {
        if (!this.disabled && this.overlayRef && !this.overlayRef.hasAttached()) {

            const tooltipPortal = new ComponentPortal(SisNgTooltipComponent);
            this.tooltipRef = this.overlayRef.attach(tooltipPortal);
            const tooltip = this.tooltipRef.instance;

            if (this.size == 'x-small' && this.text && this.text.length > 10) {
                this.size = 'small';
            }

            tooltip.id = 'sis-ng-tooltip-component';
            tooltip.text = this.text;
            tooltip.contentTemplate = this.contentTemplate;
            tooltip.disabled = this.disabled;
            tooltip.size = this.size;
            tooltip.placement = this.defaultPosition;
            tooltip.tooltipClass = this.tooltipClass;
            tooltip.title = this.title;
            tooltip.checkboxText = this.checkboxText;
            tooltip.buttonText = this.buttonText;
            tooltip.showTitle = this.showTitle;
            tooltip.showDisableCheckbox = this.showDisableCheckbox;
            tooltip.showCloseButton = this.showCloseButton;

            this.tooltipRef.instance.onHoverChange.subscribe((val: boolean) => {
                if (val) {
                    this.isTooltipHovered = val;
                    this.hide();
                } else {
                    setTimeout(() => {
                        this.isTooltipHovered = val;
                        !this.onClick && this.hide();
                    }, 0);
                }
            });

            this.tooltipRef.instance.onCheckboxClicked.subscribe((val: boolean) => {
                this.onDisableCheck.emit(val);
            });

            this.tooltipRef.instance.onCloseAction.subscribe((val: boolean) => {
                this.isClicked = false;
                this.isHostHovered = false;
                this.isTooltipHovered = false;
                this.hide();
            });
        }
    }

    /**
     * Detaches the tooltip component when neither host nor tooltip are being hovered or the tooltip popup has been closed.
     */
    private hide(): void {
        if (!(this.isHostHovered || this.isTooltipHovered || this.isClicked) && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            this.onTooltipClosed.emit(true);
        }
    }

    ngOnInit() {
        this.initTooltipOverlay();
    }

    /**
     * Initializes position strategy and creates the overlay with corresponding configs.
     */
    private initTooltipOverlay(): void {
        const positions = this.getPositions();

        const positionStrategy = this.overlayPositionBuilder
            .connectedTo(this.elementRef,
                {originX: positions[0].originX, originY: positions[0].originY},
                {overlayX: positions[0].overlayX, overlayY: positions[0].overlayY})
            .withPositions(positions);

        positionStrategy.onPositionChange.subscribe(positionChange => {
            this.tooltipRef.instance.placementType = positionChange.connectionPair;
        });

        this.overlayRef = this.overlay.create({ positionStrategy });
    }

    /**
     * Returns the preferred positions array for chosen placement.
     * @returns {ConnectionPositionPair[]}
     */
    private getPositions(): ConnectionPositionPair[] {
        switch (this.placement) {
            case 'above':       this.defaultPosition = SisTooltipPosition.UP;           break;
            case 'above-left':  this.defaultPosition = SisTooltipPosition.UP_LEFT;      break;
            case 'above-right': this.defaultPosition = SisTooltipPosition.UP_RIGHT;     break;
            case 'below':       this.defaultPosition = SisTooltipPosition.DOWN;         break;
            case 'below-left':  this.defaultPosition = SisTooltipPosition.DOWN_LEFT;    break;
            case 'below-right': this.defaultPosition = SisTooltipPosition.DOWN_RIGHT;   break;
            case 'left':        this.defaultPosition = SisTooltipPosition.LEFT;         break;
            case 'left-up':     this.defaultPosition = SisTooltipPosition.LEFT_UP;      break;
            case 'left-down':   this.defaultPosition = SisTooltipPosition.LEFT_DOWN;    break;
            case 'right':       this.defaultPosition = SisTooltipPosition.RIGHT;        break;
            case 'right-up':    this.defaultPosition = SisTooltipPosition.RIGHT_UP;     break;
            case 'right-down':  this.defaultPosition = SisTooltipPosition.RIGHT_DOWN;   break;
        }
        return SisTooltipPosition.getPositionArray(this.defaultPosition);
    }

    ngOnDestroy(): void {
        this.overlayRef.hasAttached() && this.overlayRef.detach();
    }
}


