import {Injectable, Optional} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {MessageService} from "./message.service";

@Injectable()
export class ControlsTranslationService{
    private messageService: any = null;

    constructor(@Optional() messageService: MessageService){
        this.messageService = messageService;
    }

    public getMessage (key: string): Observable<string> {
        return this.messageService ? this.messageService.getMessage(key) : Observable.of(this.getReadableKey(key));
    }

    public getMessageWithPlaceholder(key: string, placeholders: string[]): Observable<string> {
        return this.messageService ? this.messageService.getMessageWithPlaceholder(key, placeholders) : Observable.of(this.getReadableKey(key))
    }

    private getReadableKey(key: string): string {
        let parts: string[] = key.split('.');
        let text: string = parts[parts.length - 1].replace(/_/g, ' ');
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}
