import {Directive, AfterViewInit} from "@angular/core";
declare var componentHandler: any;

/**
 * This directive was added to cover the apply  material design classes on dynamic generated pages.
 * This directive must be added to all components which uses MDL.
 */
@Directive({
    selector: '[sis-mdl]'
})

export class MaterialDesignLiteUpgradeDirective implements AfterViewInit {
    ngAfterViewInit() {
        componentHandler.upgradeAllRegistered();
    }
}