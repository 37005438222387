import { Component, Input, Output, EventEmitter , ContentChild, ContentChildren, QueryList } from '@angular/core';
import {ListSorting} from './sis-list-sorting.component';
import {SortingButtonAsc} from "./sis-list-sorting-button-asc.component";
import {SortingButtonDesc} from "./sis-list-sorting-button-desc.component";
import {SortingButtonUnsorted} from "./sis-list-sorting-button-unsorted.component";

import "./sis-list-sorting-button.component.css";  @Component({
    moduleId: module.id,
    selector: 'sis-list-sorting-button',
    templateUrl: "sis-list-sorting-button.component.html"
})

export class SortingButton{

    private _sortField: string;
    private _parent: ListSorting ;

    @ContentChildren(SortingButtonAsc) sortingButtonAsc: QueryList<SortingButtonAsc>;
    @ContentChildren(SortingButtonDesc) sortingButtonDesc: QueryList<SortingButtonDesc>;
    @ContentChildren(SortingButtonUnsorted) sortingButtonUnsorted: QueryList<SortingButtonUnsorted>;

    @Input('listSorting')
    public set parent(parent : ListSorting){
        this._parent = parent;
    }

    @Input('sortField')
    public set sortField(sortField:string){
        this._sortField = sortField;
    }

    public get sortField(){
        return this._sortField;
    }

    ngAfterContentInit() {
        this._initChildren();

    }

    public getCurrentSortField(){
        return this._parent.sortField;
    }

    public isAscending() : boolean{
        return this._parent.isAscending;
    }

    public sort() : void{
        if(this.sortField === this.getCurrentSortField()) {
            this._parent.changeSorting(this._sortField, !this.isAscending());
        }
        else{
            this._parent.changeSorting(this._sortField, true);
        }
    }

    private _initChildren() {
        this.sortingButtonAsc.forEach((button)=> {
            button.parent = this._parent;
            button.sortField = this.sortField;
        });
        this.sortingButtonDesc.forEach((button)=> {
            button.parent = this._parent;
            button.sortField = this.sortField;
        });
        this.sortingButtonUnsorted.forEach((button)=> {
            button.parent = this._parent;
            button.sortField = this.sortField;
        });
    }


}
