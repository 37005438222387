/**
 * Created by Anushavan.Hovsepyan on 12/23/2016.
 */
import { Component, Input, Output, ContentChildren, QueryList, EventEmitter, OnChanges, SimpleChange, AfterContentInit } from "@angular/core";
import { TabComponent } from "./sis-tab.component";
import {SubscriptionManager} from "../shared/subscription-manager.decorator";

/**
 * @deprecated use Material tabs instead.
 * Represents the tab panel control for large number of tabs for content navigation
 * and there are visible only three tabs.
 * @class
 */
import './sis-nav-tabs.component.css'
     @Component({
    moduleId: module.id,
    selector: "sis-nav-tabs",
    templateUrl: "sis-nav-tabs.component.html"
})
@SubscriptionManager()
export class NavTabsComponent implements OnChanges, AfterContentInit {

    public $sm: SubscriptionManager;

    get selectedTabIndex(): number {
        return this._selectedTabIndex;
    }

    set selectedTabIndex(value: number) {
        this._selectedTabIndex = value;
    }

    /**
     * The tabs for component
     * @private
     * @member {Array<TabComponent>}
     * @type {Array<TabComponent>}
     */
    private _tabs: TabComponent[];

    /**
     * The selected tab index
     * @private
     * @member {number}
     * @type {number}
     */
    private _selectedTabIndex: number;

    /**
     * Holds the children of NavTabsComponent
     * @public
     * @member {QueryList<TabComponent>}
     * @type {QueryList<TabComponent>}
     */
    @ContentChildren(TabComponent)
    private _childrens: QueryList<TabComponent>;

    /**
     * Holds the currently selected tab id
     * @public
     * @member {number}
     * @type {number}
     */
    @Input("selectedTabId")
    private _selectedTabId: number;

    /**
     * Shows navigation buttons disabled or not
     * @public
     * @member {boolean}
     * @type {boolean}
     */
    @Input()
    public disableNavigation: boolean = true;

    /**
     * Used to emit the _selectedTabId change event
     * @public
     * @member {EventEmitter}
     * @type {EventEmitter}
     */
    @Output("selectedTabChange")
    private _selectedTabChange = new EventEmitter();

    /**
     * Checks the `_selectedTabId` changes and calls for `changeSelectedTab` to handle the change
     * @param {SimpleChange} changes
     * @returns {void}
     * @public
     * @method
     */
    public ngOnChanges(changes: {[ propName: string]: SimpleChange}): void {
        // check the `_selectedTabId` and select corresponding tab
        if(changes['_selectedTabId']){
            this._selectedTabId = changes['_selectedTabId'].currentValue;
            this.changeSelectedTab(this._selectedTabId);
        }
    }

    /**
     * Subscribes for `tabItems` and triggers it once
     * @returns {void}
     * @private
     * @method
     */
    public ngAfterContentInit(): void {
        this.$sm = this._childrens.changes.subscribe(() => {
            this.tabItemsChanged();
        });
        this.tabItemsChanged();
    }

    /**
     * Validates the `tabItems`, if it has no selected tab, then selects the first
     * @returns {void}
     * @private
     * @method
     */
    public tabItemsChanged(): void {
        this.validateTabItems();

        this._tabs = this._childrens.toArray();
        if (this._selectedTabId === undefined || this._selectedTabId === null) {
            this._selectedTabId = +this._tabs[0].getId();
        }
        const selectedTab = this.getTabById(this._selectedTabId);
        this._selectedTabIndex = this._tabs.indexOf(selectedTab);
        this.selectTab(this._selectedTabIndex);
    }

    /**
     * Must be called each time the `_selectedTabId` is changed, it will try to find and select a tab with new id
     * @param {number} selectedTabId
     * @returns {void}
     * @public
     * @method
     */
    public changeSelectedTab(selectedTabId: number): void {
        const tab = this.getTabById(selectedTabId);
        if (tab !== undefined && tab !== null) {
            this._selectedTabIndex = this._tabs.indexOf(tab);
            this.selectTab(this._selectedTabIndex);
        }
    }

    /**
     * Marks the given `tabItem` as `selected` and emits the `onTabSelected` event
     *         if the `tabItem` is not already selected
     *         otherwise does nothing
     * @param {number} selectedTabIndex
     * @returns {void}
     * @private
     * @method
     */
    public selectTab(selectedTabIndex: number): void {
        const selectedTab: TabComponent = this._tabs[selectedTabIndex];
        if (!selectedTab.selected && selectedTab.isEnabled) {
            // deselect the currently selected tab
            this.deselectTabs();
            // Hack to overcome an issue with 'checked binding' in Angular.
            setTimeout(() => {
                // select given tab
                selectedTab.selected = true;
            }, 1);
            this._selectedTabId = +selectedTab.getId();
            this._selectedTabIndex = this._tabs.indexOf(selectedTab);
            // emit the change event
            this._selectedTabChange.emit(selectedTab.getId());
        }
    }

    /**
     * Deselects tabs.
     */
    public deselectTabs(): void {
        this._tabs.forEach((tabItem) => {
            tabItem.selected = false;
        });
    }

    /**
     * Validates tabItems, checks for duplicate ids
     * @throw {Error} `tabItems` must be initiated and have unique ids or undefined ids
     * @returns {void}
     * @private
     * @method
     */
    public validateTabItems(): void {
        if (this._childrens === undefined || this._childrens === null) {
            throw new Error("Invalid tabItems");
        }
        const tabIds: any[] = [];
        this._childrens.forEach((tabItem) => {
            const id = tabItem.getId();
            if (-1 < tabIds.indexOf(id)) {
                throw new Error("More than one `tabItem` in `tabItems` has the same `id`: " + tabItem.getId());
            }
            if (id !== undefined && id !== null) {
                tabIds.push(tabItem.getId());
            }
        });
    }

    /**
     * Searches for a tab in `tabItems` with given `tabId`
     * @param {number} tabId
     * @returns {TabComponent | undefined}
     * @private
     * @method
     */
    public getTabById(tabId: number): TabComponent {
        let tab: TabComponent = null;
        if (this._tabs !== undefined && this._tabs !== null) {
            for (const tempTab of this._tabs) {
                if (+tempTab.getId() === tabId) {
                    tab = tempTab;
                    break;
                }
            }
        }
        return tab;
    }

    /**
     * Checkes selected tab has previous tab or not
     * @returns {TabComponent | undefined}
     * @private
     * @method
     */
    public hasPrevious(): boolean {
        return this._selectedTabIndex > 0;
    }

    /**
     * Checkes selected tab has next tab or not
     * @returns {TabComponent | undefined}
     * @private
     * @method
     */
    public hasNext(): boolean {
        return this._selectedTabIndex + 1 < this._tabs.length;
    }

    /**
     * Returns tab title.
     * @param {number} tabIndex
     * @returns {TabComponent | undefined}
     * @private
     * @method
     */
    public getTitle(tabIndex: number): string {
        if (tabIndex >= 0 && tabIndex < this._tabs.length) {
            return this._tabs[tabIndex].getTitle();
        } else {
            return "";
        }
    }
}
