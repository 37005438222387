/**
 * Created by tamara.aprikyan on 4/21/2017.
 */
import {TabComponent} from "./sis-tab.component";
import {
    AfterContentInit, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList,
    SimpleChange,
} from "@angular/core";
import {SubscriptionManager} from "../shared/subscription-manager.decorator";

/**
 * Represents the base class for tabs
 * @class
 */
@SubscriptionManager()
export class BaseTabsComponent implements OnChanges, AfterContentInit {

    public $sm: SubscriptionManager;

    /**
     * Holds the children of TabComponent
     * @public
     * @member {QueryList<TabComponent>}
     * @type {QueryList<TabComponent>}
     */
    @ContentChildren(TabComponent)
    private _tabItems: QueryList<TabComponent>;

    /**
     * Holds the currently selected tab id
     * @public
     * @member {any}
     * @type {any}
     */
    @Input()
    public selectedTabId: any;

    /**
     * Used to emit the selectedTabId change event
     * @public
     * @member {EventEmitter}
     * @type {EventEmitter}
     */
    @Output("selectedTabIdChange")
    private _selectedTabIdChange = new EventEmitter();

    public get tabItems(): QueryList<TabComponent> {
        return this._tabItems;
    }

    //From OnChanges
    /**
     * Checks the ` selectedTabId` changes and calls for `selectedTabIdChanged` to handle the change
     * @param {SimpleChange} changes
     * @returns {void}
     * @public
     * @method
     */
    public ngOnChanges(changes: {[ propName: string]: SimpleChange}): void {
        if (changes["selectedTabId"]) {
            this.selectedTabIdChanged(changes["selectedTabId"].currentValue);
        }
    }

    // From AfterContentInit
    /**
     * Subscribes for `tabItems` and triggers it once
     * @returns {void}
     * @private
     * @method
     */
    public ngAfterContentInit(): void {
        this.$sm = this._tabItems.changes.subscribe(this.tabItemsChanged.bind(this));
        this.tabItemsChanged();
    }

    /**
     * Validates the `tabItems`, if it has no selected tab, then selects the first
     * @returns {void}
     * @private
     * @method
     */
    private tabItemsChanged(): void {
        this.validateTabItems();

        // if there is no selected tab
        if (null === this.getSelectedTab()) {
            const selectedTab = this.getTabById(this.selectedTabId);
            // select the tab with selectedTabId
            if (null !== selectedTab) {
                this.selectTab(selectedTab);
            }
            // otherwise select the first tab
            else if (0 < this._tabItems.length) {
                this.selectTab(this._tabItems.first);
            }
        }
    }

    /**
     * Must be called each time the `selectedTabId` is changed, it will try to find and select a tab with new id
     * @param {any} selectedTabId
     * @returns {void}
     * @public
     * @method
     */
    private selectedTabIdChanged(selectedTabId: any): void {
        const tab = this.getTabById(selectedTabId);
        if (null !== tab) {
            this.selectTab(tab);
        }
    }

    /**
     * Marks the given `tabItem` as `selected` and emits the `onTabSelected` event
     *         if the `tabItem` is not already selected
     *         otherwise does nothing
     * @param {TabComponent} tabItem
     * @param {boolean} emitEvent emits the change event if is true
     * @returns {void}
     * @public
     * @method
     */
    public selectTab(tabItem: TabComponent): void {
        if (tabItem.selected || !tabItem.isEnabled) {
            return; // this tab is already selected
        }

        // deselect the currently selected tab
        const selectedTab: TabComponent = this.getSelectedTab();
        if (null !== selectedTab) {
            selectedTab.selected = false;
        }
        // Hack to overcome an issue with 'checked binding' in Angular.
        setTimeout(() => {
            // select given tab
            tabItem.selected = true;
        }, 1);
        // emit the change event
        this._selectedTabIdChange.emit(tabItem.getId());
    }

    /**
     * Returns the currently selected tab
     * @returns {TabComponent | undefined}
     * @private
     * @method
     */
    private getSelectedTab(): TabComponent {
        const tabItems = this._tabItems.toArray();
        for (let i = 0; i < tabItems.length; ++i) {
            if (true === tabItems[i].selected) {
                return tabItems[i];
            }
        }
        return null;
    }

    /**
     * Validates tabItems, checks for duplicate ids
     * @throw {Error} `tabItems` must be initiated and have unique ids or undefined ids
     * @returns {void}
     * @private
     * @method
     */
    private validateTabItems(): void {
        if (undefined === this._tabItems) {
            throw new Error("Invalid tabItems");
        }
        const tabIds: any[] = [];
        this._tabItems.forEach((tabItem) => {
            const id = tabItem.getId();
            if (-1 < tabIds.indexOf(id)) {
                throw new Error("More than one `tabItem` in `tabItems` has the same `id`: " + tabItem.getId());
            }
            if (undefined !== id) {
                tabIds.push(tabItem.getId());
            }
        });
    }

    /**
     * Searches for a tab in `tabItems` with given `tabId`
     * @param {any} tabId
     * @returns {TabComponent | undefined}
     * @private
     * @method
     */
    private getTabById(tabId: any): TabComponent {
        if (undefined === this._tabItems) {
            return null;
        }

        const tempTabItems = this._tabItems.toArray();
        for (let i = 0; i < tempTabItems.length; ++i) {
            if (tempTabItems[i].getId() === tabId) {
                return tempTabItems[i];
            }
        }
        return null;
    }

    onSelectedTabChanged(event: any) {
        this.selectTab(this.tabItems.toArray()[event.index]);
    }

    public trackByTitle (index: number, tab: TabComponent): string {
        return tab.getTitle();
    }
}
