/**
 * Created by pavel.arakelyan on 12/1/17.
 */
export class SelectionChange {

	readonly value: any;

	constructor(value: any) {
		this.value = value;
	}

}
