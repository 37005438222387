import {Component, AfterContentInit, ContentChildren, QueryList, Output, EventEmitter} from "@angular/core";
import {TreeNode} from "./sis-tree-node.component";
import {TreeNodeExpand} from "./sis-tree-node-expand.component";
import {TreeNodeCollapse} from "./sis-tree-node-collapse.component";

import "./sis-tree-node-expand-collapse.component.css";  @Component({
    moduleId: module.id,
    selector: "sis-tree-node-expand-collapse",
    templateUrl: "sis-tree-node-expand-collapse.component.html",
})

/**
 * Container component for tree node collapse and tree node expand components.
 */
export class TreeNodeExpandCollapse implements AfterContentInit {

    /**
     * The tree node that contains the expand/collapse icons. This node would be expanded/collapsed when the
     * corresponding child nodes are clicked.
     * @type {any}
     * @private
     */
    private _parent: TreeNode = null;

	@Output()
	onClick = new EventEmitter<boolean>();

    /**
     * The expand nodes contained in the component template.
     */
    @ContentChildren(TreeNodeExpand)
    public  childExpandNodes: QueryList<TreeNodeExpand>;

    /**
     * The collapse nodes contained in the component template.
     */
    @ContentChildren(TreeNodeCollapse)
    public  childCollapseNodes: QueryList<TreeNodeCollapse>;

    constructor() {
    }

    public set parent(parent: TreeNode) {
        this._parent = parent;
        this._initChildren();
    }

    public get parent() {
        return this._parent;
    }

    public ngAfterContentInit() {
        this._initChildren();
    }

    /**
     * Adds the parent node of all expand/collapse child nodes.
     * @private
     */
    private _initChildren() {
        this.childExpandNodes.forEach((expand) => {
            expand.parent = this._parent;
        });
        this.childCollapseNodes.forEach((collapse) => {
            collapse.parent = this._parent;
        });
    }

	clickExpandCollapse(){
		this._parent.toggleExpand();
		this.onClick.emit(this._parent.isExpanded);
    }

}
