/**
 * Created by nina.kirakosyan on 11/18/2020.
 */
import {
    ConnectionPositionPair,
    OriginConnectionPosition,
    OverlayConnectionPosition,
} from "@angular/cdk/overlay";
import {HorizontalConnectionPos, VerticalConnectionPos} from "@angular/cdk/overlay/typings/position/connected-position";

/**
 * Tooltip placement type.
 */
export declare type SisTooltipPlacement = 'left' | 'left-up' | 'left-down' | 'right' | 'right-up' | 'right-down'
    | 'above' | 'above-left' | 'above-right' | 'below' | 'below-left' | 'below-right';

/**
 * Tooltip size type.
 */
export declare type SisTooltipSize = 'x-small' | 'small' | 'medium' | 'large';

/**
 * Tooltip Position class, with predefined tooltip positioning strategy options.
 */
export class SisTooltipPosition {

    /**
     * Array of available horizontal position types.
     * @type {HorizontalConnectionPos[]}
     */
    private static readonly xPos: HorizontalConnectionPos[] = ['start', 'center', 'end'];
    /**
     * Array of available vertical position types.
     * @type {VerticalConnectionPos[]}
     */
    private static readonly yPos: VerticalConnectionPos[] = ['top', 'center', 'bottom'];

    // ConnectionPosition pairs for available tooltip placements

    public static UP: ConnectionPositionPair = SisTooltipPosition.getConPosPair(1,0,1,2);

    public static UP_LEFT: ConnectionPositionPair = SisTooltipPosition.getConPosPair(0,0,0,2);

    public static UP_RIGHT: ConnectionPositionPair = SisTooltipPosition.getConPosPair(2,0,2,2);

    public static RIGHT: ConnectionPositionPair = SisTooltipPosition.getConPosPair(2,1,0,1)

    public static RIGHT_UP: ConnectionPositionPair = SisTooltipPosition.getConPosPair(2,2,0,2);

    public static RIGHT_DOWN: ConnectionPositionPair = SisTooltipPosition.getConPosPair(2,0,0,0);

    public static DOWN: ConnectionPositionPair = SisTooltipPosition.getConPosPair(1,2,1,0);

    public static DOWN_LEFT: ConnectionPositionPair = SisTooltipPosition.getConPosPair(0,2,0,0);

    public static DOWN_RIGHT: ConnectionPositionPair = SisTooltipPosition.getConPosPair(2,2,2,0);

    public static LEFT: ConnectionPositionPair = SisTooltipPosition.getConPosPair(0,1,2,1);

    public static LEFT_UP: ConnectionPositionPair = SisTooltipPosition.getConPosPair(0,2,2,2);

    public static LEFT_DOWN: ConnectionPositionPair = SisTooltipPosition.getConPosPair(0,0,2,0);

    public static POS_UP: ConnectionPositionPair[] = [
        SisTooltipPosition.UP,
        SisTooltipPosition.UP_LEFT,
        SisTooltipPosition.UP,
        SisTooltipPosition.UP_RIGHT,
        SisTooltipPosition.UP,
        SisTooltipPosition.UP_LEFT
    ];

    public static POS_DOWN: ConnectionPositionPair[] = [
        SisTooltipPosition.DOWN,
        SisTooltipPosition.DOWN_LEFT,
        SisTooltipPosition.DOWN,
        SisTooltipPosition.DOWN_RIGHT,
        SisTooltipPosition.DOWN,
        SisTooltipPosition.DOWN_LEFT
    ];

    public static POS_LEFT: ConnectionPositionPair[] = [
        SisTooltipPosition.LEFT,
        SisTooltipPosition.LEFT_UP,
        SisTooltipPosition.LEFT,
        SisTooltipPosition.LEFT_DOWN,
        SisTooltipPosition.LEFT,
        SisTooltipPosition.LEFT_UP
    ];

    public static POS_RIGHT: ConnectionPositionPair[] = [
        SisTooltipPosition.RIGHT,
        SisTooltipPosition.RIGHT_UP,
        SisTooltipPosition.RIGHT,
        SisTooltipPosition.RIGHT_DOWN,
        SisTooltipPosition.RIGHT,
        SisTooltipPosition.RIGHT_UP
    ];

    public static POSITIONS: ConnectionPositionPair[][] = [
        SisTooltipPosition.POS_UP,
        SisTooltipPosition.POS_DOWN,
        SisTooltipPosition.POS_UP,
        SisTooltipPosition.POS_LEFT,
        SisTooltipPosition.POS_RIGHT,
        SisTooltipPosition.POS_LEFT
    ];

    /**
     * Returns the positions array for given target placement - positions
     * starting from the indicated placement, with fallback positions.
     * @returns {ConnectionPositionPair[]}
     */
    public static getPositionArray(target: ConnectionPositionPair): ConnectionPositionPair[] {
        const targetArray = SisTooltipPosition.POSITIONS.find(posArray => posArray.indexOf(target) > -1);
        const targetIndex = targetArray.indexOf(target);
        const reorderedArray = SisTooltipPosition.reorderArrayCircularly(target, targetArray);

        let fallbackArrays = SisTooltipPosition.reorderArrayCircularly(targetArray, SisTooltipPosition.POSITIONS);
        fallbackArrays = fallbackArrays.map(item =>
            item == targetArray ? reorderedArray: SisTooltipPosition.reorderArrayCircularly(item[targetIndex], item)
        );
        return [].concat.apply([], fallbackArrays);
    }

    private static reorderArrayCircularly(target: any, array: any[]): any[] {
        const i = array.indexOf(target);
        return i > -1 ? array.slice(i).concat(array.slice(0, i)) : [];
    }

    /**
     * Returns ConnectionPositionPair corresponding to passed Origin X/Y & Overlay X/Y types.
     * @param originX horizontal origin position index
     * @param originY vertical origin position index
     * @param overlayX horizontal overlay position index
     * @param overlayY vertical overlay position index
     * @returns {ConnectionPositionPair}
     */
    private static getConPosPair(originX: number, originY: number, overlayX: number, overlayY: number): ConnectionPositionPair {
        return new ConnectionPositionPair(
            SisTooltipPosition.getOriginPosition(originX, originY),
            SisTooltipPosition.getOverlayPosition(overlayX, overlayY)
        );
    }

    /**
     * Returns OriginConnectionPosition corresponding to passed X and Y types.
     * @param i horizontal position index
     * @param j vertical position index
     * @returns {OriginConnectionPosition}
     */
    private static getOriginPosition(i: number, j: number): OriginConnectionPosition {
        return {
            originX: SisTooltipPosition.xPos[i],
            originY: SisTooltipPosition.yPos[j],
        } as OriginConnectionPosition;
    }

    /**
     * Returns OverlayConnectionPosition corresponding to passed X and Y types.
     * @param i horizontal position index
     * @param j vertical position index
     * @returns {OverlayConnectionPosition}
     */
    private static getOverlayPosition(i: number, j: number): OverlayConnectionPosition {
        return {
            overlayX: SisTooltipPosition.xPos[i],
            overlayY: SisTooltipPosition.yPos[j],
        } as OverlayConnectionPosition;
    }
}
