import {Component, Input, ContentChildren, QueryList, OnChanges, SimpleChange} from "@angular/core";
import {ListPaging} from "./sis-list-paging.component";

type SortFunction = (column:string, sortAscending:boolean, item1:Object, item2:Object) => number;

import "./sis-list-sorting.component.css";  @Component({
    moduleId: module.id,
    selector: 'sis-list-sorting',
    templateUrl: "sis-list-sorting.component.html"
})

export class ListSorting implements OnChanges{

    private _items: Array<Object>;
    private _sortFunction: any;
    private _sortField: string = null;
    private _sortAscending: boolean = false;

    @ContentChildren(ListPaging) listPagingElements: QueryList<ListPaging>;


    @Input()
    public set items(items:Array<Object>){
        this._items = items.slice(0);//clone
        this._sortItems();
    }

    @Input()
    public set sortFunction(sortFunction:any){
        this._sortFunction = sortFunction;
        this._sortItems();
    }

    @Input()
    public set sortField(sortField:string){
        this._sortField = sortField;
        this._sortItems();
    }

    public get sortField(){
        return this._sortField;
    }

    public get items(){
        return this._items;
    }

    @Input()
    public set isAscending(sortAscending:boolean){
        this._sortAscending = sortAscending;
        this._sortItems();
    }

    ngOnChanges(changes : {[propKey:string]: SimpleChange}){
        changes['items'] && (this.items = changes['items'].currentValue);
    }

    public get isAscending(){
        return this._sortAscending;
    }

    public get sortedItems(){
        return this._items;
    }

    public changeSorting(sortField : string, isAscending : boolean){
        this._sortAscending = isAscending;
        this._sortField = sortField;
        this._sortItems();
        this.listPagingElements.forEach((listPaging : ListPaging) => {
            listPaging.page = 0;
        });

    }

    private _sortItems(){
        if(this._sortField && this._sortFunction){
            if(this._sortAscending){
                this._items.sort((item1, item2)=>{return this._sortFunction(this._sortField, this._sortAscending, item1, item2)});
            }
            else {
                this._items.sort((item1, item2)=>{return -1 * this._sortFunction(this._sortField, this._sortAscending, item1, item2)});
            }

        }

    }

}
