import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	forwardRef,
	Input,
	QueryList,
	ViewChildren
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import "./sis-time-picker.component.css";

const DATE_INPUT_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TimePickerComponent),
	multi: true
};

@Component({
	moduleId: module.id,
	selector: "sis-time-picker",
	templateUrl: "sis-time-picker.component.html",
	providers: [DATE_INPUT_VALUE_ACCESSOR]
})
export class TimePickerComponent implements AfterViewInit, ControlValueAccessor {
	/**
	 * Time unit
	 */
	private readonly AM: string = "AM";
	/**
	 * Time unit
	 */
	private readonly PM: string = "PM";

	private _dialogIsHoursVisible: boolean = false;
	private _dialogIsMinutesVisible: boolean = false;
	private _dialogIsPeriodVisible: boolean = false;

	private _dialogTimes: string[] = [];
	private _inputIsFocused: boolean = false;

	private _hour: string;
	private _previousHour: string;
	private _minute: string;
	private _previousMinute: string;
	private _period: string;
	private _previousPeriod: string;

	private _isNewTimePassedFromNgModel: boolean = false;
	private _isDefaultTimeEmitted: boolean = false;
	private _is12HourClockSystem = true;
	private _isTimeSelectedFromMenu = false;

	private _12HourClockSystemPattern = /^([1-9]|0[1-9]|1[0-2]):([0-5][0-9]) ([AP]M)$/;
	private _24HourClockSystemPattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

	//Placeholders for the callbacks
	private _onTouchedCallback: () => {};
	private _onChangeCallback: (...args: any[]) => {};

	/**
	 * Time picker component id
	 */
	@Input()
	public id: string;

	@Input()
	public set clockSystemPattern(pattern: string) {
		const isPreviousClockSystem12Hour = this._is12HourClockSystem;
		this._is12HourClockSystem = pattern.toUpperCase().includes("A");
		if (isPreviousClockSystem12Hour !== this._is12HourClockSystem && (this._isNewTimePassedFromNgModel || this._isDefaultTimeEmitted)) {
			this.changeClockSystem();
		}
	}

	/**
	 * Menu elements need for focusing element in menu open
	 */
	@ViewChildren("timeMenuElement")
	private timeMenuElements: QueryList<ElementRef>;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
	}

	/**
	 * SetTimeout needs because writeValue method with new passed time angular calls after ngAfterViewInit and
	 * components emits default time.
	 */
	ngAfterViewInit() {
		if (this.hour == undefined || this.minute == undefined || (this._is12HourClockSystem && this.period == undefined)) {
			if (this._is12HourClockSystem) {
				this.initDialogTimesFor12HourClockSystem();
				this._hour = this._previousHour = "12";
				this._minute = this._previousMinute = "00";
				this._period = this._previousPeriod = this.AM;
			} else {
				this.initDialogTimesFor24HourClockSystem();
				this._hour = this._previousHour = "00";
				this._minute = this._previousMinute = "00";
			}

			setTimeout(() => {
				this.emitDefaultTimeIfNeeds();
			});

			this.changeDetectorRef.detectChanges();
		}
	}

	private changeClockSystem() {
		if (this._is12HourClockSystem) {
			this.to12HourClockSystem();
			this.initDialogTimesFor12HourClockSystem();
		} else {
			this.to24HourClockSystem();
			this.initDialogTimesFor24HourClockSystem();
		}
		if (this._hour.length === 1) {
			this._hour = `0${this._hour}`;
		}
		this._onChangeCallback(this.getTime());
	}

	private to12HourClockSystem() {
		if (+this._hour > 12) {
			this._period = this.PM;
			this._hour = `${+this.hour - 12}`;
		} else if (this._hour === "12") {
			this._period = this.PM;
		} else if (this._hour === "00") {
			this._period = this.AM;
			this._hour = "12";
		} else {
			this._period = this.AM;
		}
	}

	private to24HourClockSystem() {
		if (this.period === this.AM) {
			if (this._hour === "12") {
				this._hour = "00";
			}
		} else if (+this._hour < 12) {
			this._hour = `${+this.hour + 12}`
		}
	}

	private initDialogTimesFor12HourClockSystem(): void {
		this._dialogTimes = [];
		this.initDialogTimesFor12HourClockSystemByPeriod(this.AM);
		this.initDialogTimesFor12HourClockSystemByPeriod(this.PM);
	}

	private initDialogTimesFor12HourClockSystemByPeriod(period: string): void {
		this._dialogTimes.push(`12:00 ${period}`);
		this._dialogTimes.push(`12:30 ${period}`);

		for (let i = 1; i < 10; i++) {
			this._dialogTimes.push(`0${i}:00 ${period}`);
			this._dialogTimes.push(`0${i}:30 ${period}`);
		}

		this._dialogTimes.push(`10:00 ${period}`);
		this._dialogTimes.push(`10:30 ${period}`);
		this._dialogTimes.push(`11:00 ${period}`);
		this._dialogTimes.push(`11:30 ${period}`);
	}

	private initDialogTimesFor24HourClockSystem(): void {
		this._dialogTimes = [];

		for (let i = 0; i < 10; i++) {
			this._dialogTimes.push(`0${i}:00`);
			this._dialogTimes.push(`0${i}:30`);
		}

		for (let i = 10; i < 24; i++) {
			this._dialogTimes.push(`${i}:00`);
			this._dialogTimes.push(`${i}:30`);
		}
	}

	/**
	 * if new time doesn't passed from ngModel need to emit default time.
	 * Default time in 12 hour clock system was 12:00 AM and in 24 hour clock system was 00:00
	 */
	private emitDefaultTimeIfNeeds(): void {
		if (!this._isNewTimePassedFromNgModel) {
			if (this._is12HourClockSystem) {
				if (this.getTime() === "12:00 AM") {
					this._isDefaultTimeEmitted = true;
					this._onChangeCallback("12:00 AM");
				}
			} else {
				if (this.getTime() === "00:00") {
					this._isDefaultTimeEmitted = true;
					this._onChangeCallback("00:00");
				}
			}
		}
	}

	/**
	 * From ControlValueAccessor interface
	 *
	 * @override
	 * @param {string} time - Time passed value from ngModel input in format "HH:MM" or "HH:MM A"
	 */
	writeValue(time: string) {
		this.initTimeValuesFromString(time);
	}

	/**
	 * Initializes hour, minute and if clock hour system was 12 also period fields.
	 *
	 * @param {string} time - Time in format "HH:MM" or "HH:MM A"
	 */
	private initTimeValuesFromString(time: string) {
		if (time != null) {
			const regExpMatchArray = this._is12HourClockSystem ? this._12HourClockSystemPattern.exec(time) : this._24HourClockSystemPattern.exec(time);

			if (regExpMatchArray !== null) {
				const [, hour, minute, period] = regExpMatchArray;
				this._isNewTimePassedFromNgModel = true;
				this._hour = hour.length === 1 ? `0${hour}` : hour;
				this._minute = minute;
				if (this._is12HourClockSystem) {
					this._period = period;
				}
			}
		}
	}

	/**
	 * From ControlValueAccessor interface
	 *
	 * @override
	 * @param callbackFunction ngModelChange output callback
	 */
	registerOnChange(callbackFunction: any) {
		this._onChangeCallback = callbackFunction;
	}

	/**
	 * From ControlValueAccessor interface
	 *
	 * @override
	 */
	registerOnTouched(callbackFunction: any) {
		this._onTouchedCallback = callbackFunction;
	}

	public incrementHour(): void {
		if (this._is12HourClockSystem) {
			this.incrementHourIn12HourClockSystem();
		} else {
			this.incrementHourIn24HourClockSystem()
		}

		this.generateTime();
	}

	private incrementHourIn12HourClockSystem(): void {
		switch (this._hour) {
			case "12" :
				this._hour = "01";
				break;
			case "11":
				this._hour = "12";
				this.switchPeriod();
				break;
			default:
				this._hour = `${+this._hour + 1}`;
		}
	}

	private incrementHourIn24HourClockSystem(): void {
		this._hour = this._hour === "23" ? "00" : `${+this._hour + 1}`;
	}

	public decrementHour(): void {
		if (this._is12HourClockSystem) {
			this.decrementHourIn12HourClockSystem();
		} else {
			this.decrementHourIn24HourClockSystem()
		}

		this.generateTime();
	}

	private decrementHourIn12HourClockSystem(): void {
		switch (this._hour) {
			case "12" :
				this._hour = "11";
				this.switchPeriod();
				break;
			case "01":
				this._hour = "12";
				break;
			default:
				this._hour = `${+this._hour - 1}`;
		}
	}

	private decrementHourIn24HourClockSystem(): void {
		this._hour = this._hour === "00" ? "23" : `${+this._hour - 1}`;
	}

	public onHourScroll(scrollEvent: WheelEvent): void {
		// We getting vertical scroll amount from detail, as deltaY property of wheel event isn't available in Mozilla.
		const verticalScrollAmount = scrollEvent.deltaY || scrollEvent.detail;
		const isScrolledUp = verticalScrollAmount < 0;
		if (isScrolledUp) {
			this.incrementHour();
		} else {
			this.decrementHour();
		}
	}

	public onHourChange(hour: string, hourElement: HTMLInputElement): void {
		if (hour !== this._hour) {
			if (this._is12HourClockSystem) {
				if (hour === "" || +hour === 0) {
					this._hour = "";
				} else if (+hour > 0 && +hour < 13) {
					this._hour = hour;
				} else {
					this._hour = "12";
				}
			} else {
				if (hour === "") {
					this._hour = "";
				} else if (+hour < 0) {
					this._hour = "00";
				} else if (+hour > 23) {
					this._hour = "23";
				} else {
					this._hour = hour;
				}
			}

			hourElement.value = this._hour;
		}
	}

	public onMinuteScroll(scrollEvent: WheelEvent): void {
		// We getting vertical scroll amount from detail, as deltaY property of wheel event isn't available in Mozilla.
		const verticalScrollAmount = scrollEvent.deltaY || scrollEvent.detail;
		this.changeMinute(verticalScrollAmount < 0);
	}

	public changeMinute(isIncremented: boolean): void {
		if (+this._minute === 0) {
			if (isIncremented) {
				this._minute = "01";
			} else {
				this._minute = "59";
				this.decrementHour();
			}
		} else if (+this._minute === 59) {
			if (isIncremented) {
				this._minute = "00";
				this.incrementHour();
			} else {
				this._minute = "58";
			}
		} else {
			this._minute = isIncremented ? `${+this._minute + 1}` : `${+this._minute - 1}`;
			if (this._minute.length === 1) {
				this._minute = `0${this._minute}`;
			}
		}
		this.generateTime();
	}

	public onMinuteChange(minute: string, minuteElement: HTMLInputElement): void {
		if (minute !== this._minute) {
			this._minute = minute === "" ? "" : +minute > -1 && +minute < 60 ? `${minute}` : "59";
			minuteElement.value = this._minute;
		}
	}

	public onPeriodChange(period: string, periodElement: HTMLInputElement): void {
		if (period !== this._period) {
			period = period.toUpperCase();
			const isPeriod = period === this.AM || period === this.PM;
			this._period = isPeriod ? period : this.AM;
			periodElement.value = this._period;
		}
	}

	public switchPeriodWithGeneratingTime(): void {
		this.switchPeriod();
		this.generateTime();
	}

	private switchPeriod(): void {
		this._period = this._period === this.AM ? this.PM : this.AM;
	}

	public selectTime(time: string): void {
		this._isTimeSelectedFromMenu = true;
		this.removeActiveClassFromTimeMenuList();
		this.initTimeValuesFromString(time);
		const generatedTime: string = this.getTime();
		this._onChangeCallback(generatedTime);
	}

	public onInputFocus(focusedElementType: string): void {
		this._inputIsFocused = true;

		switch (focusedElementType) {
			case "hour":
				this._dialogIsHoursVisible = true;
				this._dialogIsMinutesVisible = false;
				this._dialogIsPeriodVisible = false;
				this._previousHour = this._hour;
				break;
			case "minute":
				this._dialogIsHoursVisible = false;
				this._dialogIsMinutesVisible = true;
				this._dialogIsPeriodVisible = false;
				this._previousMinute = this._minute;
				break;
			case "period":
				this._dialogIsHoursVisible = false;
				this._dialogIsMinutesVisible = false;
				this._dialogIsPeriodVisible = true;
				this._previousPeriod = this._period;
				break;
		}
	}

	public onInputFocusOut() {
		this._inputIsFocused = false;
		this._dialogIsHoursVisible = false;
		this._dialogIsMinutesVisible = false;
		this._dialogIsPeriodVisible = false;
		this.generateTime();
	}

	public generateTime(): void {
		if (this._hour === "") {
			this._hour = this._previousHour;
		}
		if (this._minute === "") {
			this._minute = this._previousMinute;
		}
		if (this._hour.length === 1) {
			this._hour = `0${this._hour}`
		}
		if (this._minute.length === 1) {
			this._minute = `0${this._minute}`
		}
		const generatedTime: string = this.getTime();
		this._onChangeCallback(generatedTime);
	}

	private getTime(): string {
		return this._is12HourClockSystem ? `${this._hour}:${this._minute} ${this._period}` : `${this._hour}:${this._minute}`;
	}

	public onTimeMenuOpen(): void {
		const nativeElement: HTMLButtonElement = this.getTimeMenuElementByCurrentTime();

		nativeElement.focus();
		if (this._minute === "00" || this._minute === "30") {
			nativeElement.classList.add("ng-time-picker-menu__item--selected");
		}
	}

	public onTimeMenuClose(): void {
		if (!this._isTimeSelectedFromMenu) {
			this.removeActiveClassFromTimeMenuList();
		} else {
			this._isTimeSelectedFromMenu = false;
		}
	}

	private removeActiveClassFromTimeMenuList() {
		const nativeElement = this.getTimeMenuElementByCurrentTime();
		if (nativeElement !== null) {
			nativeElement.classList.remove("ng-time-picker-menu__item--selected");
		}
	}

	private getTimeMenuElementByCurrentTime(): HTMLButtonElement {
		const time = this.getRoundedTime();
		return this.timeMenuElements.find(elementRef => elementRef.nativeElement.innerText === time).nativeElement;
	}

	private getRoundedTime(): string {
		let time = this.getTime();
		if (this._minute !== "00" && this._minute !== "30") {
			let searchText: string = `:${this._minute}`;
			let replaceValue: string;

			if (+this._minute < 16) {
				replaceValue = ":00";
			} else if (+this._minute > 45) {
				let newHour = this._hour === "23" ? this._hour : `${+this._hour + 1}`;
				if (newHour.length === 1) {
					newHour = `0${newHour}`;
				}
				searchText = `${this._hour}:${this._minute}`;
				replaceValue = `${newHour}:00`;
			} else {
				replaceValue = ":30";
			}
			time = time.replace(searchText, replaceValue);
		}

		return time;
	}

	get hour(): string {
		return this._hour;
	}

	get minute(): string {
		return this._minute;
	}

	get period(): string {
		return this._period;
	}

	get dialogIsHoursVisible(): boolean {
		return this._dialogIsHoursVisible;
	}

	get dialogIsMinutesVisible(): boolean {
		return this._dialogIsMinutesVisible;
	}

	get dialogIsPeriodVisible(): boolean {
		return this._dialogIsPeriodVisible;
	}

	get dialogTimes(): string[] {
		return this._dialogTimes;
	}

	get inputIsFocused(): boolean {
		return this._inputIsFocused;
	}

	get is12HourClockSystem(): boolean {
		return this._is12HourClockSystem;
	}
}
