import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {StringTemplate} from "@synisys/idm-common-util-frontend";

import "./sis-paging.component.css";  @Component({
    moduleId: module.id,
    selector: 'sis-paging',
    templateUrl: "sis-paging.component.html"
})
export class Paging implements OnInit{
    private _totalPageCount: number = 0;
    private _page: number = 0;
    private _pageSize: number = 0;
    private _pages: Array<number> = [];
    private _itemsCount: number;
    private readonly firstItemPlaceholder: string = 'first';
    private readonly lastItemPlaceholder: string = 'last';
    private readonly totalPlaceholder: string = 'total';

    @Input()
    public defaultItemsNumbersPerPage: number[];

    private _defaultNumbersPerPage: number[] = [10, 25, 50, 100, 200];

    public itemsNumbersPerPage: number[] = [];

    @Input()
    public showFirstLastButtons: boolean = true;

    /**
     * @deprecated - For backward compatibility only.
     * This property is for paging component 'of' label.
     */
    @Input() outOfLabel: string = "of";

    @Input()
    public message: StringTemplate = StringTemplate.createTemplate`${this.firstItemPlaceholder} - ${this.lastItemPlaceholder} of ${this.totalPlaceholder}`;

    @Input()
    public set totalPageCount(totalPageCount: number) {
        this._totalPageCount = totalPageCount;
        this._initPages();
    }

    public get totalPageCount() {
        return this._totalPageCount;
    }

    @Input('page')
    public set page(page: number) {
        this._page = page;
    }

    public get page() {
        return this._page;
    }

    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    @Input()
    public set pageSize(pageSize: number) {
        this._pageSize = pageSize;
    }

    public get pageSize() {
        return this._pageSize;
    }

    @Output()
    public pageSizeChange: EventEmitter<number> = new EventEmitter();

    @Input()
    public set itemsCount(itemsCount: number) {
        this._itemsCount = itemsCount;

        if (this.isItemsPerPageSelectEnabled) {
            this.initItemsNumbersPerPage()
        }
    }

    public get itemsCount() {
        return this._itemsCount;
    }

    @Input()
    public itemsPerPageMessage: string = 'Items per page:';

    @Input()
    public isItemsPerPageSelectEnabled: boolean = false;

    public changePage(page: number) {
        this._page = page;
        this.pageChange.emit(this._page);
    }

    public get pages() {
        return this._pages;
    }

    public get firstItemIndex() {
        return this._itemsCount !== 0 ? this._page * this._pageSize + 1 : 0;
    }

    public get lastItemIndex() {
        return this._itemsCount === 0 ? 0
            : (this._page !== this._pages[this._pages.length - 1] ? (this._page + 1) * this._pageSize : this._itemsCount);
    }

    private _initPages() {
        this._pages = [];
        for (let i = 0; i < this._totalPageCount; i++) {
            this._pages.push(i);
        }
    }

    public changePageSize(pageSize: number) {
        this._page = Math.floor(this._page * this._pageSize / pageSize);
        this._pageSize = pageSize;
        const totalPageCount: number = this._itemsCount / this._pageSize;
        this.totalPageCount = Math.ceil(totalPageCount);
        this.pageSizeChange.emit(pageSize);
        this.pageChange.emit(this._page);
    }

    private initItemsNumbersPerPage() {
        const itemsPerPageArray = this.defaultItemsNumbersPerPage ? this.defaultItemsNumbersPerPage : this._defaultNumbersPerPage;

        this.itemsNumbersPerPage = itemsPerPageArray.filter((itemsCountPerPage: number) => {
            return itemsCountPerPage <= this._itemsCount;
        });

        if (this.itemsNumbersPerPage.length == 0 && this.itemsCount !== 0) {
            this.itemsNumbersPerPage.push(this.itemsCount);
            this.changePageSize(this.itemsCount);
        }

        if (this.itemsCount !== 0 && this.itemsNumbersPerPage.indexOf(this._pageSize) < 0) {
            this.changePageSize(this.itemsNumbersPerPage[0]);
        }
    }

    ngOnInit(): void {
        if (this.isItemsPerPageSelectEnabled) {
            this.initItemsNumbersPerPage()
        }
    }
}
