import {Component} from "@angular/core";
import {TreeNode} from "./sis-tree-node.component";

import "./sis-tree-node-expand.component.css";  @Component({
    moduleId: module.id,
    selector: "sis-tree-node-expand",
    templateUrl: "sis-tree-node-expand.component.html",
})

/**
 * TreeNodeCollapse enables the users to expand the nodes and to provide the design of expand node.
 */
export class TreeNodeExpand {

    /**
     * The node that contains the expand icon. This node would be expanded when the expand node is clicked.
     * @type {TreeNode}
     * @private
     */
    private _parent: TreeNode = null;

    constructor() {

    }

    public set parent(parent: TreeNode) {
        this._parent = parent;
    }

    public get parent() {
        return this._parent;
    }
}
